import { FC } from 'react'


const About: FC = () => {
  return (
    <>
      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-10'>
        <div className='col-12'>
          <h2 className='text-end mb-5'>درباره ما</h2>
          <p className='fs-5'>
            ما یک پلتفرم اجتماعی برای اتصال تولیدکنندگان، بازرگانان، ارائه‌دهندگان خدمات، عمده‌فروشان و خرده‌فروشان طراحی کرده‌ایم. هدف ما ایجاد فضایی است که تمامی صنوف بتوانند محصولات و خدمات خود را به بهترین شکل معرفی کنند و ارتباطات تجاری کارآمدی برقرار کنند.
            با تمرکز بر نوآوری، سهولت استفاده و ایجاد فرصت‌های رشد، ما تلاش می‌کنیم بستری را فراهم کنیم که همه کاربران بتوانند در فضای رقابتی امروز موفق‌تر عمل کنند. به ما بپیوندید و بخشی از این تحول باشید.          </p>
        </div>

      </div>
      {/* end::Row */}
    </>
  )
}

export { About }
