import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useLayout } from '../../core';
import { KTIcon } from '../../../helpers';
import { Footer } from './Footer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';

const FooterWrapper = () => {
  const { config } = useLayout();
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const [isShopMenuOpen, setIsShopMenuOpen] = useState(false); // State برای مدیریت باز و بسته شدن باکس
  const location = useLocation();
  const { currentUser } = useAuth();
  const navigate = useNavigate()

  const menuItems = [
    { path: '/dashboard', icon: 'abstract-30', label: 'پیشخوان' },
    { path: '/search', icon: 'basket', label: 'بازار' },
    { path: '/map', icon: 'geolocation', label: 'نقشه' },
    { path: '/setting', icon: 'setting-2', label: 'تنظیمات' },
  ];

  useEffect(() => {
    // بررسی اندازه صفحه
    const handleResize = () => {
      setIsMobileOrTablet(window.innerWidth <= 1024); // موبایل و تبلت
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!config.app?.footer?.display) {
    return null;
  }

  // تابع برای مدیریت کلیک روی لینک فروشگاه
  const handleShopClick = () => {
    if (currentUser?.shopUsername) {
      setIsShopMenuOpen(!isShopMenuOpen); // باز یا بسته شدن باکس
    } else {
      navigate('/NewShop'); 
    }
  };

  return (
    <div className='app-footer' id='kt_app_footer'>
      {config.app.footer.containerClass ? (
        <div className={clsx('app-container', config.app.footer.containerClass)}>
          <Footer />
        </div>
      ) : (
        <Footer />
      )}
      {isMobileOrTablet && (
        <div className="footer-container">
          <div className="bar">
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className={clsx('bar-item text-center', {
                  active: location.pathname === item.path,
                })}
              >
                <div className="bar-fluid"></div>
                <div className="drop"></div>
                <KTIcon iconName={`${item.icon}`} className='' />
                <span className="bar-label">{item.label}</span>
              </Link>
            ))}
            {/* لینک فروشگاه */}
            <div
              className={clsx('bar-item text-center', {
                active: location.pathname === '/NewShop',
              })}
              onClick={handleShopClick}
            >
              <div className="bar-fluid"></div>
              <div className="drop"></div>
              <KTIcon iconName='abstract-10' className='' />
              <span className="bar-label">
                {currentUser?.shopUsername ? 'فروشگاه' : 'فروشگاه'}
              </span>
            </div>
          </div>

          {/* باکس لینک‌های فروشگاه */}
          {isShopMenuOpen && currentUser?.shopUsername && (
            <div className="shop-menu">
              <Link to={`/shop/${currentUser.shopUsername}`} className="shop-menu-item">
                مشاهده فروشگاه
              </Link>
              <Link to={`/shop/${currentUser.shopUsername}/addProduct`} className="shop-menu-item">
                ایجاد محصول
              </Link>
              <Link to={`/shop/${currentUser.shopUsername}/addPost`} className="shop-menu-item">
                ایجاد مقاله
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { FooterWrapper };