import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const LOGOUT_USER = `${API_URL}/logout`
export const SMS_VERIFICATION_URL = `${API_URL}/send_verification_code`

// Server should return AuthModel
export function login(phone: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    phone,
    password,
  })
}

export function register(
  phone: string,
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
  ref: string
) {
  return axios.post(REGISTER_URL, {
    phone,
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
    ref,
  })
}


export function sendVerificationCode(
  phone: string,
  email: string,
  code: string,
) {
  return axios.post(SMS_VERIFICATION_URL, {
    phone: phone,
    email: email,
    code: code,
  })
}

// Server should return object => { result: boolean } (Is phone in DB)
export function requestPassword(phone: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    phone,
  })
}

export function getUserByToken() {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
  })
}

export function logoutUser() {
  return axios.post<UserModel>(LOGOUT_USER, {
  })
}
