/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react'
import { KTIcon } from '../../../helpers'
import { getUpgradePrice, upgrade } from './_requests'
import Swal from 'sweetalert2';

type Prices = {
  [key: string]: {
    monthly: string
    annual: string
    shop: string
    product: string
    article: string
  }
}

const UpgradePlan: FC<{ onClose: () => void }> = ({ onClose }) => {
  const [plans, setPlans] = useState([
    {
      title: 'استارتاپ',
      subTitle: 'بهترین برای کسب‌وکارها',
      priceMonth: '',
      priceAnnual: '',
      default: true,
      custom: false,
      features: [
        { title: '', supported: true },
        { title: '', supported: true },
        { title: '', supported: true },
        { title: '', supported: true },
      ],
    },
    {
      title: 'پیشرفته',
      subTitle: 'بهترین برای تولید کنندگان',
      priceMonth: '',
      priceAnnual: '',
      default: false,
      custom: false,
      features: [
        { title: 'تا 35 فروشگاه فعال', supported: true },
        { title: 'تا 30 محصول', supported: true },
        { title: 'تا 60 مقاله', supported: true },
        { title: 'فضای ابری نامحدود', supported: true },
      ],
    },
    {
      title: 'سازمانی',
      subTitle: 'بهترین برای شرکت‌ها',
      priceMonth: '',
      priceAnnual: '',
      label: 'محبوب‌ترین',
      default: false,
      custom: false,
      features: [
        { title: 'بی نهایت فروشگاه فعال', supported: true },
        { title: 'بی نهایت محصول', supported: true },
        { title: 'بی نهایت مقاله', supported: true },
        { title: 'فضای ابری نامحدود', supported: true },
      ],
    },
  ])

  const [currentState, setCurrentState] = useState<'month' | 'annual'>('month')
  const [selected, setSelected] = useState('استارتاپ')

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const prices = (await getUpgradePrice()).data as Prices;
        const updatedPlans = plans.map((plan) => {
          const planData = prices[plan.title]
          if (planData) {
            return {
              ...plan,
              priceMonth: planData.monthly || plan.priceMonth,
              priceAnnual: planData.annual || plan.priceAnnual,
              features: [
                // { title: `تا ${planData.shop} فروشگاه فعال`, supported: parseInt(planData.shop) > 0 },
                { title: `تا ${planData.product} محصول ماهانه`, supported: parseInt(planData.product) > 0 },
                { title: `تا ${planData.article} مقاله ماهانه`, supported: parseInt(planData.article) > 0 },
                { title: 'فضای ابری نامحدود', supported: true },
              ],
            }
          }
          return plan
        });
        setPlans(updatedPlans)
      } catch (error) {
        console.error('Error fetching prices:', error)
      }
    }

    fetchPrices();
  }, [])

  const handleUpgrade = async () => {
    try {
      const response = await upgrade(selected, currentState);
      Swal.fire({
        icon: 'success',
        title: 'موفقیت‌آمیز!',
        text: response.data.message || 'ارتقاء با موفقیت انجام شد.',
        confirmButtonText: 'باشه',
      })

      onClose()
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'خطا',
        text: error.response?.data?.message || 'مشکلی در انجام عملیات رخ داده است.',
        confirmButtonText: 'باشه',
      })
    }
  }


  return (
    <div className='modal fade show d-block' tabIndex={-1} aria-modal='true'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <button onClick={onClose} className='btn btn-sm btn-icon btn-active-color-primary'>
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>

          <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>ارتقاء طرح</h1>
              <div className='text-muted fw-bold fs-5'>
                برای اطلاعات بیشتر از طریق <a href='/contact' className='link-primary fw-bolder'>راه‌های ارتباطی</a> با ما در ارتباط باشید.
              </div>
            </div>

            <div className='d-flex flex-column'>
              <div className='nav-group nav-group-outline mx-auto' data-kt-buttons='true'>
                <a
                  href='#'
                  className={
                    'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                    (currentState === 'month' && 'active')
                  }
                  onClick={() => {
                    setCurrentState('month')
                  }}
                  data-kt-plan='month'
                >
                  ماهانه
                </a>
                <a
                  href='#'
                  className={
                    'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                    (currentState === 'annual' && 'active')
                  }
                  onClick={() => {
                    setCurrentState('annual')
                  }}
                  data-kt-plan='annual'
                >
                  سالانه
                </a>
              </div>

              <div className='row mt-10'>
                <div className='col-lg-6 mb-10 mb-lg-0'>
                  <div className='nav flex-column'>
                    {plans.map((plan, index) => (
                      <div
                        onClick={() => {
                          setSelected(plan.title)
                        }}
                        className={
                          `nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 ` +
                          (index !== plans.length - 1 && 'mb-6 ') +
                          (plan.default && 'active ') +
                          (!plan.custom && 'btn-active btn-active-primary ')
                        }
                        data-bs-toggle='tab'
                        data-bs-target={`#kt_upgrade_plan_${index}`}
                        key={index}
                      >
                        <div className='d-flex align-items-center me-2'>
                          <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='plan'
                              value={plan.title}
                              checked={selected === plan.title}
                              onChange={(e) => setSelected(e.target.value)}
                            />
                          </div>
                          <div className='flex-grow-1'>
                            <h2 className='d-flex align-items-center fs-2 fw-bolder flex-wrap'>
                              {plan.title}
                              {plan.label && (
                                <span className='badge badge-light-success me-2 fs-7'>
                                  {plan.label}
                                </span>
                              )}
                            </h2>
                            <div className='fw-bold opacity-50'>{plan.subTitle}</div>
                          </div>
                        </div>

                        <div className='ms-5'>
                          {!plan.custom && (
                            <>

                              <span data-kt-element='period'>ماه</span>/
                              <span className='fs-3x fw-bolder'>
                                {currentState === 'month' ? plan.priceMonth : plan.priceAnnual}
                              </span>
                              <span className='fs-7 opacity-50'>
                                <span className='mb-2'>تومان</span>
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='tab-content rounded h-100 bg-light p-10'>
                    {plans
                      .filter(plan => plan.title === selected)
                      .map((plan, index) => (
                        <div key={`custom${index}`}>
                          {!plan.custom && (
                            <div
                              className={`tab-pane fade` + (plan.default && 'show active')}
                              id={`kt_upgrade_plan_${index}`}
                            >
                              <div className='pt-1'>
                                {plan.features!.map((feature, i) => (
                                  <div
                                    className={
                                      `d-flex align-items-center` +
                                      (i !== plan.features!.length - 1 && ' mb-7')
                                    }
                                    key={`${i}-${feature.title}`}
                                  >
                                    {feature.supported ? (
                                      <>
                                        <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                          {feature.title}
                                        </span>
                                        <KTIcon
                                          iconName='check-circle'
                                          className='fs-1 text-success'
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <span className='fw-bold fs-5 text-gray-400 flex-grow-1'>
                                          {feature.title}
                                        </span>
                                        <KTIcon iconName='cross-circle' className='fs-1' />
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-center flex-row-fluid pt-12'>
              <button type='reset' onClick={onClose} className='btn btn-light ms-3' data-bs-dismiss='modal'>
                انصراف
              </button>

              <button type='submit' className='btn btn-primary' onClick={handleUpgrade}>
                ارتقاء عضویت
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { UpgradePlan }
