import { FC } from 'react'


const ContactUs: FC = () => {
  return (
    <>
      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-10'>
        <div className='col-12'>
          <h2 className='text-end mb-5'>تماس با ما</h2>
          <p className='fs-5'>
            جهت دریافت مشاوره کسب و کار و مراجعه حضوری از شنبه تا چهارشنبه از ساعت ۱۲ الی ۲۰ تماس حاصل نمایید.
            <br />
            کاربران ویژه دیجی نمایشگاه جهت آموزش یا مشاوره بدون هماهنگی قبلی از ساعت ۹ الی ۱۸ امکان تماس خواهند داشت.
            <br />
            <br />
            ایمیل: info@diginamayeshgah.com
            <br />
            تلگرام: <a href="https://t.me/diginamayeshgah1" target="_blank" rel="noopener noreferrer">diginamayeshgah1@</a>
            <br />
            ایتا: <a href="https://eitaa.com/diginamayeshgah" target="_blank" rel="noopener noreferrer">diginamayeshgah@</a>
          </p>
        </div>

      </div>
      {/* end::Row */}
    </>
  )
}

export { ContactUs }


