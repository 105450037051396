import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { updateUser, updateUserPassword } from './_requests'
import Swal from 'sweetalert2'
import { useAuth } from '../../../app/modules/auth'
import { UserModel } from '../../modules/auth/core/_models'

// اسکیمای اعتبارسنجی فیلدهای فروشگاه
const userDetailsSchema = Yup.object().shape({
  phone: Yup.string().required('ورود شماره تلفن الزامی است.'),
  email: Yup.string().required('انتخاب ایمیل الزامی است.'),
  first_name: Yup.string().required('انتخاب نام الزامی است.'),
  last_name: Yup.string().required('ورود نام خانوادگی الزامی است.'),
  national_card_image: Yup.mixed()
    .nullable()
    .test('fileType', 'فقط فرمت‌های PNG و JPEG مجاز است.', (value) => {
      if (!value) return true; // اگر مقدار خالی است، خطا ندارد.
      if (typeof value === 'string') {
        // بررسی کنید آیا مقدار به صورت data URL است.
        const mimeType = value.split(';')[0]?.split(':')[1];
        return mimeType === 'image/jpeg' || mimeType === 'image/png';
      } else if (value instanceof File) {
        // اگر مقدار یک فایل است، فرمت را بررسی کنید.
        return value.type === 'image/jpg' || value.type === 'image/jpeg' || value.type === 'image/png';
      }
      return false; // در غیر این صورت، خطا بده.
    })
    .test('fileSize', 'حجم فایل نباید بیشتر از ۵ مگابایت باشد.', (value) => {
      if (!value) return true;
      if (value instanceof File) {
        return value.size <= 5 * 1024 * 1024;
      }
      return true; // برای data URL ها خطا ندارد.
    }),
})

const passwordChangeSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required('رمز عبور فعلی الزامی است.')
    .min(8, 'حداقل کارکتر مجاز رمزعبور 8 کارکتر است')
    .max(50, 'حداکثر کارکتر مجاز رمزعبور 50 کارکتر است')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
      'رمز عبور باید شامل حروف و اعداد باشد.'
    ),
  newPassword: Yup.string()
    .required('رمز عبور جدید الزامی است.')
    .min(8, 'حداقل کارکتر مجاز رمزعبور 8 کارکتر است')
    .max(50, 'حداکثر کارکتر مجاز رمزعبور 50 کارکتر است')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
      'رمز عبور باید شامل حروف و اعداد باشد.'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'رمز عبور و تکرار آن یکسان نیستند.')
    .required('تکرار رمز عبور الزامی است.'),
})




const Setting: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth()

  const [loadingPasswordChange, setLoadingPasswordChange] = useState(false)

  const formikPasswordChange = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: passwordChangeSchema,
    onSubmit: async (values) => {
      setLoadingPasswordChange(true);
      try {
        await updateUserPassword({ password: values.newPassword, currentPassword: values.currentPassword, confirmed: values.confirmPassword })

        await Swal.fire({
          icon: 'success',
          title: 'موفقیت!',
          text: 'رمز عبور با موفقیت تغییر یافت.',
          confirmButtonText: 'باشه!',
        })

        formikPasswordChange.resetForm();
      } catch (error) {
        await Swal.fire({
          icon: 'error',
          title: 'خطا!',
          text: 'خطایی در تغییر رمز عبور رخ داد. لطفاً دوباره تلاش کنید.',
          confirmButtonText: 'باشه!',
        })
      } finally {
        setLoadingPasswordChange(false)
      }
    },
  })


  const formik = useFormik<UserModel>({
    initialValues: {
      id: currentUser?.id || 0,
      veryfied: currentUser?.veryfied || 0,
      phone: currentUser?.phone || '',
      email: currentUser?.email || '',
      first_name: currentUser?.first_name || '',
      last_name: currentUser?.last_name || '',
      wallet: currentUser?.wallet || 0,
      plan: currentUser?.plan || null,
      plan_expiry: currentUser?.plan_expiry || null,
      ref_num: currentUser?.ref_num || 0,
      ref_income: currentUser?.ref_income || 0,
      shopUsername: currentUser?.shopUsername || null,
      national_card_image: currentUser?.national_card_image || null,
      unread_messages_count: currentUser?.unread_messages_count || 0,
    },
    validationSchema: userDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        // ارسال داده‌ها به سرور
        await updateUser(values)

        await Swal.fire({
          icon: 'success',
          title: 'موفقیت!',
          text: 'تغییرات با موفقیت ذخیره شد.',
          confirmButtonText: 'بزن بریم!',
        })
      } catch (error) {
        console.error('Error updating shop:', error)

        const errorResponse = error as { response?: { data?: { message?: string, errors?: Record<string, string[]> } } }
        let errorMessage = 'خطایی در هنگام به‌روزرسانی فروشگاه رخ داد.'

        if (errorResponse.response?.data?.message) {
          errorMessage = errorResponse.response.data.message
        }

        if (errorResponse.response?.data?.errors) {
          const errorDetails = Object.values(errorResponse.response.data.errors).flat()
          errorMessage = errorDetails.join(', ')
        }

        await Swal.fire({
          icon: 'error',
          title: 'خطا!',
          text: errorMessage,
          confirmButtonText: 'بسیار خب!',
        })
      } finally {
        setLoading(false)
      }
    },
  })

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => formik.setFieldValue('national_card_image', reader.result);
      reader.readAsDataURL(file);
    }
  }

  return (
    <>
      <div className='card mb-xl-10 p-5'>
        <div id='kt_account_profile_details' className='collapse show'>
          <h3 className="mb-5">تغییر مشخصات کاربری</h3>

          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              {/* First Name */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>نام</label>
                <div className='col-lg-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='نام'
                    {...formik.getFieldProps('first_name')}
                  />
                  {formik.touched.first_name && formik.errors.first_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.first_name}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Last Name */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>نام خانوادگی</label>
                <div className='col-lg-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='نام خانوادگی'
                    {...formik.getFieldProps('last_name')}
                  />
                  {formik.touched.last_name && formik.errors.last_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.last_name}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Phone (Non-editable) */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>موبایل</label>
                <div className='col-lg-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='موبایل'
                    value={formik.values.phone}
                    readOnly
                  />
                </div>
              </div>

              {/* Email */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>ایمیل</label>
                <div className='col-lg-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='ایمیل'
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* National Card Image */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>تصویر کارت ملی</label>
                <small>با ارسال تصویر کارت ملی، فروشگاه خود را احراز هویت نمایید.</small>
                <div className='col-lg-8 mt-5'>
                  <div
                    className='image-input image-input-outline shadow-sm'
                    style={{
                      backgroundImage: `url(${formik.values.national_card_image || '/media/avatars/blank.png'})`,
                    }}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px d-flex align-items-center justify-content-center'
                      style={{
                        cursor: 'pointer',
                        backgroundColor: '#f5f5f5',
                        transition: 'all 0.3s',
                      }}
                      onClick={() => document.getElementById('national_card_image')?.click()}
                      onMouseEnter={(e) => (e.currentTarget.style.opacity = '0.8')}
                      onMouseLeave={(e) => (e.currentTarget.style.opacity = '1')}
                    >
                      {formik.values.national_card_image ? (
                        <img
                          src={
                            formik.values.national_card_image ||
                            '/media/avatars/blank.png'
                          }
                          alt='تصویر کارت ملی'
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />

                      ) : (
                        <span className='text-muted'>آپلود کارت ملی</span>
                      )}
                    </div>
                    <input
                      type='file'
                      id='national_card_image'
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />

                  </div>
                  {formik.touched.national_card_image && formik.errors.national_card_image && (
                    <div className='text-danger mt-2'>{formik.errors.national_card_image}</div>
                  )}

                </div>
              </div>



              {/* Submit Button */}
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && 'ذخیره'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      لطفا صبر کنید... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="card mt-10 p-5">
        <h3 className="mb-5">تغییر رمز عبور</h3>

        <form onSubmit={formikPasswordChange.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            {/* Current Password */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">رمز عبور فعلی</label>
              <div className="col-lg-8">
                <input
                  type="password"
                  className="form-control form-control-lg form-control-solid"
                  {...formikPasswordChange.getFieldProps('currentPassword')}
                />
                {formikPasswordChange.touched.currentPassword && formikPasswordChange.errors.currentPassword && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">{formikPasswordChange.errors.currentPassword}</div>
                  </div>
                )}
              </div>
            </div>

            {/* New Password */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">رمز عبور جدید</label>
              <div className="col-lg-8">
                <input
                  type="password"
                  className="form-control form-control-lg form-control-solid"
                  {...formikPasswordChange.getFieldProps('newPassword')}
                />
                {formikPasswordChange.touched.newPassword && formikPasswordChange.errors.newPassword && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">{formikPasswordChange.errors.newPassword}</div>
                  </div>
                )}
              </div>
            </div>

            {/* Confirm Password */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">تکرار رمز عبور جدید</label>
              <div className="col-lg-8">
                <input
                  type="password"
                  className="form-control form-control-lg form-control-solid"
                  {...formikPasswordChange.getFieldProps('confirmPassword')}
                />
                {formikPasswordChange.touched.confirmPassword && formikPasswordChange.errors.confirmPassword && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">{formikPasswordChange.errors.confirmPassword}</div>
                  </div>
                )}
              </div>
            </div>

            {/* Submit Button */}
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" disabled={loadingPasswordChange}>
                {loadingPasswordChange ? (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    لطفا صبر کنید... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                ) : (
                  'تغییر رمز عبور'
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

    </>
  )
}

export { Setting }
