import { FC } from 'react'
import { KTIcon } from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'

const Chat: FC = () => {
  const { currentUser } = useAuth()

  // تبدیل unread_messages_count به عدد و بررسی مقدار
  const unreadCount = currentUser?.unread_messages_count
    ? parseInt(currentUser.unread_messages_count, 10)
    : 0

  return (
    <>
      <a
        href='/apps/chat'
        className='d-flex align-items-stretch position-relative'
      >
        <div className='d-flex align-items-center'>
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTIcon iconName='messages' className='fs-1' />
          </div>
        </div>
        {unreadCount > 0 && (
          <span
            className='position-absolute top-10 start-100 translate-middle badge badge-circle text-white bg-danger fs-8'
          >
            {unreadCount}
          </span>
        )}
      </a>
    </>
  )
}

export { Chat }
